import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Services/PageBanner-services'
import Footer from "../components/App/Footer"
import ServicesOne from '../components/services/ServicesOne'
import AgilityPromo from '../components/Common/AgilityPromo'
import Banner from '../assets/images/services/graphic-services.png'

// import BannerCover from '../assets/images/services/radial-cover.png'

const Services = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Cyber Security Services" 
                homePageText="Information &amp; Cyber Security Services" 
                homePageUrl="/" 
                animation="spin"
                screenShot={Banner}
                // screenShot2={BannerCover}
                activePageText="Services" 
            />
            <ServicesOne /> 
            <AgilityPromo /> 
            <Footer />
        </Layout>
    );
}

export default Services